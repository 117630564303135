<template>
  <div :style="`overflow: auto; width: 100%; max-height: ${height}px;`">
    <table class="mx-table-full" :id="table_id">
      <thead>
      <tr class="red">
        <th style="width: 140px; max-width: 140px; min-width: 140px; left: 0;" :class="`sticky-col first-col ${isMobile ? 'first-col-mobile' : ''}`">
          {{ !isMobile ? 'First name' : 'Fullname' }}
        </th>
        <th style="width: 140px; max-width: 140px; min-width: 140px; left: 140px" v-if="!isMobile" class="sticky-col second-col">Lastname</th>
        <template>
          <th style="min-width: 240px; max-width: 240px;">Email</th>
          <th style="min-width: 100px; max-width: 100px;">Gender</th>
          <th style="min-width: 100px; max-width: 100px;">Birthday</th>
          <th style="min-width: 140px; max-width: 140px;">Phone</th>
          <th style="min-width: 140px; max-width: 140px;">Mobile</th>
        </template>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in table" :key="index">
        <td :class="`sticky-col first-col ${isMobile ? 'first-col-mobile' : ''}`">
          {{ isMobile ? item.first_name[0] + '.' + item.last_name : item.first_name }}
        </td>
        <td v-if="!isMobile" class="sticky-col second-col">{{ item.last_name }}</td>
        <template>
          <td>{{ item.email }}</td>
          <td>{{ item.gender }}</td>
          <td>{{ item.birthday }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.mobile }}</td>
        </template>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    table: Array,
    table_id: {
      type: String,
      default: 'ts'
    }
  },
  data() {
    return {
      isMobile: true,
      height: 0,
    }
  },
  created() {
    setTimeout(this.tableHeight, 300)
  },
  mounted() {
    this.tableHeight()
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    tableHeight() {
      this.height = window.innerHeight - (document.getElementById(this.table_id).getBoundingClientRect().top + 20)
    }
  }
}
</script>

<style lang="scss">

.theme--light {
  $bd-color: #eaeaea;
  $border-color: #e8eaec;

  $head-color: #7e7e7e;
  $head-bg: #f4f4f4;

  $color: #555555;
  $body-bg: #ffffff;

  table[class='mx-table-full'] {
    background: white;
    color: $color;
    thead {
      th {
        background: $head-bg;
        color: $head-color;
      }
    }
    tbody {
      td {
        border-bottom: 1px solid $border-color;
        background: $body-bg;
      }
    }
    th {
      background: white;
    }
    .first-col {

      &-mobile {
        box-shadow: 3px 0 3px -2px #888;
        background: #e8eaec;
        //border-bottom: 1px solid #dddddd;
      }
    }
    .second-col {
      border-right: 1px solid $border-color;
    }
  }
}

table[class='mx-table-full'] {
  font-weight: 400;
  text-align: left;
  position: relative;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  th, td {
    padding: 14px 18px;
    //min-width: 150px;
    font-weight: 400;
    font-size: 16px;
  }
  th {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  thead {
    th {
      font-size: 12px;
    }
    .sticky-col {
      z-index: 3;
    }
  }
  .sticky-col {
    position: -webkit-sticky;
    position: sticky;
  }
  .first-col {
    left: 0;
  }
  .second-col {
    left: 140px;
  }
}




</style>