<template>
  <div>
    <v-container fluid>
      <v-row class="mt-4">
        <v-col cols="12" sm="6" md="4" xl="3">
          <v-text-field
              label="Suche..."
              placeholder="Suche..."
              v-model="search.search"
              solo
              clearable
              hide-details
          ></v-text-field>
        </v-col>
        <v-col
            class="d-flex ml-md-n5"
            cols="12"
            sm="6"
            md="4"
            xl="3"
        >
          <v-select
              v-model="search.search_select"
              :items="search_from"
              label="Search from"
              :append-icon="'mdi-chevron-down'"
              solo
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
          <v-btn
              style="padding: 24px 0 25px 0;"
              elevation="2"
              color="primary"
              medium
              class="ml-1"
          >
            <v-icon size="32">mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <TableFull :table="table" table_id="ts"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TableFull from "@/components/TableFull";

export default {
  components: {TableFull},
  data() {
    return {
      table_header: [
        {title: '', width: ''}
      ],
      table: [
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
        {
          first_name: 'Alexander',
          last_name: 'Bechthold',
          email: 'maxxidomgermany@gmail.com',
          gender: 'Male',
          birthday: '26.12.1982',
          phone: '0211261800701',
          mobile: '0176303087354'
        },
      ],
      search: {
        search: null,
        search_select: 'phone'
      },
      search_from: [
        {
          text: 'First name',
          value: 'first_name'
        },
        {
          text: 'Last name',
          value: 'last_name'
        },
        {
          text: 'Phone',
          value: 'phone'
        },
        {
          text: 'Mobile',
          value: 'mobile'
        }
      ]
    }
  }
}
</script>